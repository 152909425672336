.regionSelector {
    gap: 20px;
    width: 170px;
    height: 44px;
    border-radius: 50px;
    background-color: #f3f8fb;
    color: #0184db;
    position: relative;
    cursor: pointer;
    z-index: 5;

    .selected {
        height: inherit;
        display: flex;
        gap: 10px;
        align-items: center;
        padding: 0 12px;
        position:relative;

        svg {
            width: 24px;
            height: 24px;
        }

        .dropdownArrow {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: auto;
            transition: all 0.3s ease;
            svg {
                width: 13px;
                height: 13px;
            }
        }
    }

    .regionOptions {
        display: flex;
        position: relative;
        top: -5px;
        flex-flow: column nowrap;
        padding: 0;
        border-radius: 0 0 20px 20px;
        align-items: center;
        background-color: inherit;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);

        a {
            width: 100%;
            padding: 10px 0 10px 45px;
            text-align: left;
            position:relative;
            transition: all 0.2s ease;

            &:last-child {
                border-radius: 0 0 20px 20px;
            }

            &:hover {
                background-color: #fdfdff;
            }

            &:before {
                content: '\25CF';
                width: 2px;
                height: 2px;
                position: absolute;
                color: #0184db;
                left: 12%;
                top: 27%;
                font-size: 14px;
            }
        }
    }
}


.opened {
    box-shadow: 0 4px 2px 0 rgba(0, 0, 0, 0.1);
    border-radius: 20px 20px 0 0;
}

@media screen and (max-width: 1100px) {
    .regionSelector {

        .selected {
            gap: 7px;

            svg {
                width: 20px;
                height: 20px;
            }
        }

        .regionOptions {

            a {
                padding-left: 40px;

                &:before {
                    left: 10%;
                    font-size: 12px;
                }
            }
        }
    }
}